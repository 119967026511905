<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="user-authorization">
            <div class="org-panel">
                <el-tree :data="orgList"
                         default-expand-all
                         :expand-on-click-node="false"
                         class="org-tree"
                         @current-change="orgChangeHandle">
                    <span slot-scope="{ node, data }"
                          class="el-tree-node__label">{{ data.entity.organization.organizationLanguages[0].name }}</span>
                </el-tree>
            </div>
            <div class="user-panel ui-layout-content--full">
                <el-form ref="form" size="small" :inline="true" :model="queryUser" class="el-form--compact" label-position="top">
                    <el-row :gutter="12">
                        <el-col :span="5">
                            <el-form-item :label="'姓名'">
                                <el-input v-model="queryUser.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :label="'移动电话'">
                                <el-input v-model="queryUser.mobile"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :label="'账号'">
                                <el-input v-model="queryUser.account"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :label="'邮箱'">
                                <el-input v-model="queryUser.email"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item :label="'全局搜索'">
                                <el-select v-model="queryUser.global"
                                           filterable
                                           :placeholder="'请选择'"
                                           style="width: 93%">
                                    <el-option v-for="item in keyValues.globalList"
                                               :key="item.key"
                                               :label="item.value"
                                               :value="item.key"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="3">
                            <el-form-item style="margin-top: 20px;">
                                <el-button type="primary"
                                           @click="handleCurrentChange(1)"
                                           round
                class="ex-button ex-button--submit">{{'查询'}}</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table :data="userList"
                          class="user-list-table"
                          highlight-current-row
                          height="720"
                          style="width: 100%"
                          @current-change="userChangeHandle">
                    <el-table-column type="index"></el-table-column>
                    <el-table-column property="user.userLanguages[0].name"
                                     :label="'姓名'"></el-table-column>
                    <el-table-column property="user.extraInfo.mobile"
                                     :label="'移动电话'"></el-table-column>
                    <el-table-column property="user.extraInfo.email"
                                     :label="'邮箱'"></el-table-column>
                </el-table>
                <div class="ui-layout-content--full ui-align--right">
                    <el-pagination :current-page="pagination.pageNumber"
                                   :page-size="pagination.pageSize"
                                   :page-sizes="[15, 50, 100]"
                                   :total="pagination.total"
                                   @current-change="handleCurrentChange"
                                   @size-change="handleSizeChange"
                                   layout="total, prev, pager, next, jumper, sizes"></el-pagination>
                </div>
            </div>
            <div class="role-panel">
                <el-collapse accordion class="auth-panel__collapse">
                    <el-collapse-item :title="currentOrgName+'(组织机构)'"
                                      name="1"
                                      class="auth-panel__collapse-item">
                        <el-checkbox-group v-model="currentOrgRoleCodes"
                                           size="small"
                                           class="checkbox-wrap">
                            <div class="checkbox"
                                 v-for="item in roleList"
                                 :key="item.code"
                                 :value="item.code">
                                <el-checkbox :label="item.code"
                                             :disabled="!currentOrgCode">{{ item.roleLanguages[0].name }}</el-checkbox>
                            </div>
                        </el-checkbox-group>
                        <div class="operation-wrap">
                            <el-button type="primary"
                                       size="small"
                                       :disabled="!currentOrgCode"
                                       @click="saveOrgHandle">{{'保存'}}</el-button>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item :title="currentUserName + '(用户)'"
                                      name="2"
                                      class="auth-panel__collapse-item">
                        <el-checkbox-group v-model="currentUserRoleCodes"
                                           size="small"
                                           class="checkbox-wrap">
                            <div class="checkbox"
                                 v-for="item in roleList"
                                 :key="item.code"
                                 :value="item.code">
                                <el-checkbox :label="item.code"
                                             :disabled="!currentUserId">{{ item.name?item.name:item.roleLanguages[0].name }}</el-checkbox>
                            </div>
                        </el-checkbox-group>
                        <div class="operation-wrap">
                            <el-button type="primary"
                                       size="small"
                                       :disabled="!currentUserId"
                                       @click="saveUserHandle">{{'保存'}}</el-button>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserAuthorization',
    data() {
        return {
            orgList: [],
            userList: [],
            roleList: [],
            queryUser: {
                name: '',
                mobile: '',
                email: '',
                account: '',
                global: 'false',
            },
            pagination: {
                pageSize: 15,
                pageNumber: 1,
                total: 0
            },
            depth: '',
            currentOrgCode: '',
            currentOrgId: '',
            currentUserName: '',
            currentOrgRoleCodes: [],
            currentOrgDataOrigin: null,
            currentUserId: '',
            currentOrgName: '',
            currentUserRoleCodes: [],
            currentUserDataOrigin: null,
            keyValues: {
                globalList: [],
            },
        };
    },
    methods: {
        getGlobal() {
            this.$enum('yesorno').then(data => {
                this.keyValues.globalList = data.map(item => {
                    return {
                        ...item,
                        key: item.key + ''
                    };
                });
            });
        },
        getUserAuth() {
            const params = {
                pageNumber: 1,
                pageSize: 999
            };
            this.$client.getRoleList(params).then(data => {
                this.roleList = data.items || [];
            });
        },
        getOrgTree() {
            this.$client.getOrgCodeTree().then(data => {
                this.orgList = data || [];
            });
        },
        handleCurrentChange(page) {
            this.pagination.pageNumber = page;
            this.handleQueryUser();
        },
        handleSizeChange(size) {
            this.pagination.pageSize = size;
            this.handleQueryUser();
        },
        orgChangeHandle(data) {
            this.depth = data.entity.depth;
            this.currentOrgCode = data.entity.organization.code;
            this.currentOrgId = data.entity.organization.id;
            this.currentOrgName = data.entity.organization.organizationLanguages[0].name;
            this.currentOrgRoleCodes = [...data.entity.roleCodes];
            this.currentOrgDataOrigin = data;
            const params = {
                orgId: this.currentOrgId,
                pageNumber: 1,
                pageSize: this.pagination.pageSize
            };
            this.$client.getUserList(params).then(data => {
                this.userList = data.items;
                this.pagination.total = data.totalItemCount;
            });
        },
        userChangeHandle(row) {
            if (row) {
                this.currentUserId = row.user.id;
                this.currentUserRoleCodes = [...row.roleCodes];
                this.currentUserDataOrigin = row;
                this.currentUserName = row.user.userLanguages[0].name;
            } else {
                this.currentUserId = '';
                this.currentUserName = '';
                this.currentUserRoleCodes = [];
                this.currentUserDataOrigin = null;
            }
        },
        saveOrgHandle() {
            const data = {
                orgCode: this.currentOrgCode,
                roleCodes: this.currentOrgRoleCodes
            };
            this.$client.saveOrgAuth(data).then(() => {
                this.currentOrgDataOrigin.entity.roleCodes = [...data.roleCodes];
                this.$message({
                    message: '操作成功！',
                    type: 'success'
                });
            });
        },
        saveUserHandle() {
            const data = {
                userId: this.currentUserId,
                roleCodes: this.currentUserRoleCodes
            };
            this.$client.saveUserAuth(data).then(() => {
                this.currentUserDataOrigin.roleCodes = [...data.roleCodes];
                this.$message({
                    message: '操作成功！',
                    type: 'success'
                });
            });
        },
        handleQueryUser() {
            const queryFilter = {
                name: this.queryUser.name,
                mobile: this.queryUser.mobile,
                email: this.queryUser.email,
                account: this.queryUser.account,
                global: this.queryUser.global,
                pageNumber: this.pagination.pageNumber,
                pageSize: this.pagination.pageSize
            };
            if (queryFilter.global === 'false') {
                queryFilter.orgId = this.currentOrgId;
            } else {
                queryFilter.orgId = '';
            }
            this.$client.getUserList(queryFilter).then(data => {
                this.userList = data.items;
                this.pagination.total = data.totalItemCount;
            });
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    },
    created() {
        this.getUserAuth();
        this.getOrgTree();
        this.getGlobal();
    }
};
</script>
<style lang="less" scoped>
.user-authorization {
    display: flex;
    flex-direction: row;
    height: 100%;
    box-sizing: border-box;
    .org-panel {
        width: 20%;
        flex-shrink: 0;
        height: 100%;
        border-right: 1px solid #ddd;
        box-sizing: border-box;
        overflow: auto;
        padding-right: 10px;
        /deep/ .el-tree-node > .el-tree-node__children {
            overflow: visible;
        }

        /deep/ .el-tree-node.is-current > .el-tree-node__content,
        /deep/ .el-tree-node.is-current:focus > .el-tree-node__content {
            background-color: #fbf4c3;
        }
    }

    .user-panel {
        flex: 1;
        width: 60%;
        padding: 0 10px;
        height: 100%;
        overflow: auto;
        .user-page {
            text-align: left;
        }

        /deep/ .el-table--enable-row-hover .el-table__body tr.current-row:hover > td {
            background-color: #fbf4c3;
        }

        /deep/ .user-list-column > .cell {
            overflow: visible;
            white-space: nowrap;
        }
    }

    .role-panel {
        width: 20%;
        flex-shrink: 0;
        height: 100%;
        overflow: auto;
        border-left: 1px solid #ddd;
        padding-left: 10px;
        box-sizing: border-box;
        &__collapse {
            height: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            &-item {
                &.is-active {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    /deep/ .el-collapse-item__wrap {
                        overflow: auto;
                    }
                }

                /deep/ .el-collapse-item__wrap {
                    position: relative;
                    flex: 1;
                    .el-collapse-item__content {
                        position: relative;
                        height: 600px;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 0;
                    }
                }

                .checkbox-wrap {
                    overflow: auto;
                    max-height: calc(~'100% - 50px');
                    .checkbox {
                        margin-left: 10px;
                        display: block;
                    }
                }

                .operation-wrap {
                    position: absolute;
                    bottom: 10px;
                }
            }
        }
    }
}
</style>
